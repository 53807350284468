import Bugsnag from '@bugsnag/js';
import { getISOTimestamp } from 'src/apollo/utils';
import { getUserIDHmacToken } from 'src/auth';

export enum SEVERITY {
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export const logBugsnag = (severity: SEVERITY, message: string) => {
  Bugsnag.notify(message, function (event) {
    event.severity = severity;
  });
};

export const getEventSecurityLogContext = (context: Record<string, any>) => {
  const timestamp = getISOTimestamp();
  const hashedUserID = getUserIDHmacToken();

  return {
    ...context,
    timestamp,
    hashedUserID,
  };
};

export const logContentSecurityPolicyViolation = (
  event: SecurityPolicyViolationEvent,
) => {
  const context = JSON.stringify({
    message: `Content Security Policy Violation of ${event.violatedDirective}`,
    blockedURI: event.blockedURI,
    disposition: event.disposition,
    documentURI: event.documentURI,
    effectiveDirective: event.effectiveDirective,
    statusCode: event.statusCode,
    sourceFile: event.sourceFile,
    violatedDirective: event.violatedDirective,
  });

  logBugsnag(SEVERITY.error, context);
};
