import { ApolloLink, DocumentNode, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { matchPath } from 'react-router-dom';
import { AuthStatus, getAuthStatus } from 'src/auth';
import { routes as routesBestOfRing } from 'src/features/BestOfRing/routes';
import { history } from 'src/history';
import { useHasWebViewUserAgent } from 'src/hooks';
import { logBugsnag, getEventSecurityLogContext, SEVERITY } from 'src/logs';
import { AppRoutes, pathWithWV } from 'src/routes';
import { goToLogin, reLoginWebview } from 'src/utils';
import { NeighborsErrorCodes } from './codes';
import { hasGqlErrorCode, hasGqlErrorCodes } from './utils';

const httpLink = new HttpLink({
  uri: '/query',
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationName = operation.operationName;
  const successStatus = 'success: false';

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, extensions } = error;
      const notFound = extensions?.code === NeighborsErrorCodes.NotFound;

      const finalMessage = `[GraphQL error]: ${message}`;

      const context = {
        message: finalMessage,
        successStatus,
        operationName,
        error,
      };

      if (!notFound) {
        logBugsnag(
          SEVERITY.error,
          JSON.stringify(getEventSecurityLogContext(context)),
        );
      }
    });
  }

  if (networkError) {
    const message = `[Network error]: ${networkError.message}`;

    const context = {
      message,
      successStatus,
      operationName,
      networkError,
    };

    logBugsnag(
      SEVERITY.error,
      JSON.stringify(getEventSecurityLogContext(context)),
    );
  }
});

const redirectLink = onError((error) => {
  const {
    location: { pathname },
  } = history;
  const isWebview = useHasWebViewUserAgent();

  const noRedirectPagesMatch = matchPath(pathname, {
    path: [
      AppRoutes.NotFound,
      AppRoutes.EventDetailOrSharePage,
      AppRoutes.BetterNeighborhoods,
      AppRoutes.Deals,
      AppRoutes.PetTag,
      AppRoutes.EmergencyInfo,
      AppRoutes.Terms,
      AppRoutes.PetMarketingPage,
      AppRoutes.PetTagFlyer,
      AppRoutes.PetProfile,
      AppRoutes.BestOfRing + routesBestOfRing.BestOfRingVideoFullscreen,
      ...pathWithWV(AppRoutes.PetTagFlyer),
      ...pathWithWV(AppRoutes.PetProfile),
      ...pathWithWV(AppRoutes.BestOfRing + routesBestOfRing.BestOfRingFeed),
      ...pathWithWV(
        AppRoutes.BestOfRing + routesBestOfRing.BestOfRingVideoFullscreen,
      ),
    ],
  });

  const authStatus = getAuthStatus();

  // we need to avoid redirecting if:
  //   * the user is visiting the event page
  //   * the user is visiting the better neighborhoods page AND the webview search param is present
  if (
    noRedirectPagesMatch?.isExact &&
    [AuthStatus.pending, AuthStatus.unauthenticated].includes(authStatus)
  ) {
    return;
  }

  const unauthorizedOrSessionTimedOut = hasGqlErrorCodes(error, [
    NeighborsErrorCodes.Unauthorized,
    NeighborsErrorCodes.SessionTimeout,
  ]);

  if (unauthorizedOrSessionTimedOut) {
    //Do not redirect to log in page when user is authenticated and there's an UNAUTHORIZED error
    if (
      hasGqlErrorCode(error, NeighborsErrorCodes.Unauthorized) &&
      authStatus === AuthStatus.authenticated
    ) {
      return;
    }
    if (isWebview) {
      reLoginWebview();
    } else {
      goToLogin();
    }
  }
});

interface DocumentNodeWithId extends DocumentNode {
  documentId?: string;
}

const persistedQueriesLink = createPersistedQueryLink({
  generateHash: ({ documentId }: DocumentNodeWithId) => documentId!,
});

export const link = from(
  [errorLink, redirectLink, persistedQueriesLink, httpLink].filter(
    Boolean,
  ) as ApolloLink[],
);
